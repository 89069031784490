import React from 'react';
import { createGlobalStyle } from 'styled-components'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { getMenus } from './utils'
import { connect } from 'react-redux'
import { setMenus } from './actions';
import Page from './components/Page';
import Header from './components/Header';
import Menu from './components/Menu';
import Background from './components/Background';
import styled from 'styled-components'
import NotFound from './components/NotFound';

window.templateDir = window.templateDir || "http://pantar.com/wp-content/themes/pantar"

const GlobalStyles = createGlobalStyle`
  :root {
    --big-spacing: 40px;
    --big-spacing-mobile: 40px;
    --main-spacing: 30px;
    --main-spacing-mobile: 24px;
  }

  * {
    box-sizing: border-box;
  }

  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }


  button,
  a:link, a:visited {
    text-decoration: none;
    color: black;

    &:hover {
      color: #7B7A7A;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    font-weight: normal;
    line-height: 1.2;
    margin-top: calc((var(--main-spacing) - 4px) * 2);
    margin-bottom: 0;

    @media only screen and (max-width: 600px) {
      margin-top: calc((var(--main-spacing-mobile) - 4px) * 2);
    }
  }

  h1,
  h2,
  h3 {
    font-size: 32px;
  }

  em, i {
    font-style: normal;
    font-family: 'GraphikRegularItalic';
  }

  strong {
    font-family: 'GraphikSemibold';
    font-weight: normal;

    em, i {
      font-family: 'GraphikSemiboldItalic';
    }
  }

  p {
    line-height: 1.5;
    margin: var(--main-spacing) 0 0;

    @media only screen and (max-width: 600px) {
      margin: var(--main-spacing-mobile) 0 0;
    }

    &:last-child {
      margin-top: 0;
    }

    /* &:only-child {
      margin: var(--main-spacing) 0 0;

      @media only screen and (max-width: 600px) {
        margin: var(--main-spacing-mobile) 0 0;
      }
    } */

    &.has-trim-text {
      display: inline;
    }
  }

  .with-inline-paragraphs {
    margin-top: var(--main-spacing);

    @media only screen and (max-width: 600px) {
      margin-top: var(--main-spacing-mobile);
    }
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    display: inline-block;
    width: 100%;
  }

  body {
    margin: 0;
    color: #7B7A7A;
    background-color: white;
    font-family: 'GraphikRegular', Helvetica, sans-serif;
    transition: all 0.2s linear;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  .has-text-align-center {
    text-align: center;
  }

  .no-style-button {
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    font-size: inherit;
    font-family: inherit;
    background-color: transparent;
  }
`

const AppContainer = styled.div`
  /* height: 100%; */
  position: relative;
  z-index: 2;
  overflow: hidden;
`

class App extends React.PureComponent {
  componentDidMount() {
    getMenus()
      .then(menus => {
        this.props.dispatch(setMenus(menus))
      })
  }
  render() {
    return (
      <>
        <GlobalStyles />
        <AppContainer>
          <Router>
            <Header />
            <Switch>
              <Route path="/:slug" exact component={Page} />
              <Route path="/" exact component={Page} />
              <Route path="*" exact component={NotFound} />
            </Switch>
            <Menu />
          </Router>
        </AppContainer>
        <Background />
      </>
    );
  }
}

export default connect(state => {
  return {}
})(App);
