import React, { PureComponent } from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import {
  Link
} from "react-router-dom";
import { origin } from '../utils';
import { arrowRight } from '../icons';
import striptags from 'striptags'


const Wrapper = styled.ul`
  margin-top: var(--main-spacing);

  @media only screen and (max-width: 600px) {
    margin-top: var(--main-spacing-mobile);
  }

  a {
    margin-left: 8px;
  }
`

const ListItem = styled.li`
  margin-top: var(--main-spacing);

  @media only screen and (max-width: 600px) {
    margin-top: var(--main-spacing-mobile);
  }

  &:first-child {
    margin-top: 0;
  }
`

const ListItemReadMore = styled.button`

`

const maxElements = 5

export default class ListWithLinks extends PureComponent {
  state = {
    showReadMore: false,
    elements: [],
    shortElements: []
  }

  getData(block) {
    const data = block.attrs.data
    const elements = []

    if (data.repeater) {
      for (let index = 0; index < data.repeater; index++) {
        elements.push({
          text: data[`repeater_${index}_text`],
          link: data[`repeater_${index}_link`]
        })
      }
    }

    return elements
  }

  componentDidMount() {
    const { block } = this.props
    const elements = this.getData(block)
    const shortElements = [...elements].splice(0, maxElements)
    this.setState({ elements, shortElements, showReadMore: elements.length > maxElements })
  }

  render() {
    const { elements, shortElements, showReadMore } = this.state

    if (!elements.length) {
      return null
    }

    const elementsArray = showReadMore ? shortElements : elements

    return (
      <Wrapper>
        {elementsArray.map((elem, index) => {
          const link = elem.link
          const hasLink = link !== ''
          let linkTemplate = null
          if (hasLink) {
            const title = ReactHtmlParser(link.title) || 'Visit Site'
            linkTemplate = <a href={link.url} rel="noopener noreferrer" target='_blank'>{title} {arrowRight}</a>
            if (!link.target && link.url.includes(origin)) {
              linkTemplate = <Link to={link.url.replace(origin, '')}>{title} {arrowRight}</Link>
            }
          }
          return <ListItem key={`list_${index}`}>
            {ReactHtmlParser(striptags(elem.text, ['em']))}
            {linkTemplate}
          </ListItem>
        })}
        {elements.length > maxElements && <ListItem>
          <ListItemReadMore
            className="no-style-button"
            onClick={() => this.setState({ showReadMore: !showReadMore })}>
            {showReadMore ? 'More...' : 'Less...'}
          </ListItemReadMore>
        </ListItem>}
      </Wrapper>
    )
  }
}
