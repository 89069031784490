const initialState = {
  menus: false,
  homepage: '',
  showMenu: false,
  colorStops: null,
  colorStopIndex: 0,
  currentPage: '',
  pages: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case 'SET_MENUS':
      return { ...state, menus: payload }
    case 'SET_HOMEPAGE':
      return { ...state, homepage: payload }
    case 'SET_COLOR_STOPS':
      return { ...state, colorStops: payload }
      case 'SET_COLOR_STOP_INDEX':
      return { ...state, colorStopIndex: payload }
    case 'SHOW_MENU':
      return { ...state, showMenu: payload }
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: payload }
    case 'SET_PAGE':
      return {
        ...state,
        pages: {
          ...state.pages,
          ...payload
        }
      }

    default:
      return state
  }
}
