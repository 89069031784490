import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Player from '@vimeo/player'
import { play, pauseIcon, loader } from '../icons'
import _get from 'lodash/get'
import { formatTime, isMobile, getParameterByName } from '../utils'
import Plyr from 'plyr'

const VideoWrapper = styled.div`
  .plyr__control--overlaid,
  .plyr__controls,
  .ytp-expand-pause-overlay,
  .vp-outro {
    display: none!important;
  }
  .plyr--youtube {
    pointer-events: none!important;
  }
  .plyr__poster {
    background-size: cover;
  }

  ${props => props.isFirstBlock ? css`
    height: 100%;
    position: relative;

    @media only screen and (max-width: 600px) {
      height: 100%;
    }
  ` : css`
    position: relative;
    display: ${props => props.isVisible ? 'block' : 'none'};
    max-height: 630px;
    height: 55vw;
    background-image: linear-gradient(to right,rgba(85,85,85,.4) 0%,rgba(85,85,85,0) 5%,rgba(85,85,85,0) 95%,rgba(85,85,85,.4) 100%);
  `}
  pointer-events: ${props => props.isVisible ? 'auto' : 'none'};
  overflow: hidden;
`
const volumeOffIcon = <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24.39 9L14.47 19.77H8V30.23H14.47L24.39 41V9Z" fill="white" />
  <path d="M30.06 19.5L41.06 30.5" stroke="white" strokeMiterlimit="10" />
  <path d="M41.06 19.5L30.06 30.5" stroke="white" strokeMiterlimit="10" />
</svg>


const volumeOnIcon = <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M31.86 15.3C37.22 15.3 41.56 19.64 41.56 25C41.56 30.36 37.22 34.7001 31.86 34.7001" stroke="white" strokeMiterlimit="10" />
  <path d="M31.86 21.77C33.65 21.77 35.09 23.22 35.09 25C35.09 26.79 33.64 28.24 31.86 28.24" stroke="white" strokeMiterlimit="10" />
  <path d="M25.39 9L15.47 19.77H9V30.23H15.47L25.39 41V9Z" fill="white" />
</svg>

const VolumeContainer = styled.div`
  position: absolute;
  z-index: 10;
  right: calc(var(--main-spacing) - 8px);
  top: calc(var(--main-spacing) * 2 + 16px);

  @media only screen and (max-width: 600px) {
    right: calc(var(--main-spacing-mobile) - 8px);
    top: calc(var(--main-spacing-mobile) * 2 + 8px);
  }
`

const VideoIframe = styled.div`

  ${props => props.isFirstBlock ? css`
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  ` : css`
    height: 100%;
    width: 100%;

    iframe {
      height: 100%;
      width: 100%;
    }
  `}
`

const VideoIframeContainer = styled.div`
  height: 100%;
`

const CentralButton = styled.button`
  position: absolute;
  z-index: 2;
  width: 74px;
  top: 50%;
  left: 50%;
  margin: -37px 0 0 -37px;

  @media only screen and (max-width: 600px) {
    width: 48px;
    margin: -24px 0 0 -24px;

    svg {
      width: 48px;
      height: 48px;
    }
  }

  ${props => !props.isMobile ? css`
    &:hover {
      .pause-icon,
      .play-icon {
        polyline,
        path {
          fill: #7B7A7A;
          stroke: #7B7A7A;
        }
      }
    }
  ` : null}
`

const Controls = styled.div`
  position: absolute;
  opacity: ${props => props.showControls ? 1 : 0};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  svg.loader {
    width: 74px;
    height: 74px;

    @media only screen and (max-width: 600px) {
      width: 48px;
      height: 48px;
    }
  }
`

const TimeContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  padding: 0 var(--main-spacing) var(--main-spacing);

  @media only screen and (max-width: 600px) {
    padding: 0 var(--main-spacing-mobile) var(--main-spacing-mobile);
  }
`

const VideoOverlay = styled.div`
  position: absolute;
  background-color: ${props => props.isPlaying ? 'transparent' : 'rgba(0,0,0,.5)'};
  background-image: ${props => props.imagePreviewUrl ? `url(${props.imagePreviewUrl})` : 'none'};
  background-size: cover;
  background-position: center center;
  transition: all .3s linear;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  top: 0;
  ${props => !props.isMobile ? css`
    &:hover {
      ${Controls} {
        opacity: ${props => props.isPlaying ? 1 : 0};
      }
    }
  ` : null}
`

const TimeLine = styled.input`
  height: 1px;
  flex: 1;
  background-color: white;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &::-moz-range-thumb {
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    ${props => !props.isMobile ? css`
      &:hover {
        background: #7B7A7A;
      }
    ` : null}

  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;

    ${props => !props.isMobile ? css`
      &:hover {
        background: #7B7A7A;
      }
    ` : null}
  }
`

const Time = styled.div`
  line-height: 1;
  margin-right: var(--main-spacing);
`

const TimeLineWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`

const TimeLinePrediction = styled.div`
  position: absolute;
  left: ${props => `calc(${props.xPos}px - 36px)`};
  top: -40px;
  background-color: rgba(0, 0, 0, .3);
  border-radius: 4px;
  padding: 4px;
`

export default class VideoPlayer extends PureComponent {
  state = {
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    isReady: false,
    player: false,
    timePosition: 0,
    isBuffering: false,
    timeValue: 0,
    showMobileControls: false,
    volumeOn: false
  }

  constructor(props) {
    super(props)

    this.playerRef = React.createRef()
    this.range = React.createRef()
  }

  componentDidMount() {
    const { autoplay, isVisible, isFirstBlock, loop } = this.props

    if (this.isVimeoLink()) {
      const player = new Player(this.playerRef.current, {
        url: this.props.videoUrl,
        responsive: isFirstBlock,
        controls: false,
        autopause: false,
        portrait: false,
        muted: !!autoplay,
        loop: !!autoplay && !!loop
      })

      if (autoplay && isVisible) {
        player.play()
        this.setState({ isPlaying: true })
      }

      if (!autoplay) {
        player.on('ended', this.onEnded)
        player.on('timeupdate', this.onProgress)
        player.on('bufferstart', this.onBufferStart)
        player.on('bufferend', this.onBufferEnd)
      }

      player.on('pause', this.pause)

      this.setState({ player })

      Promise.all([player.ready(), player.getDuration()]).then(result => {
        this.setState({ isReady: true, duration: result[1] })
      })
    } else {
      if (this.isYoutubeLink()) {
        const { videoUrl } = this.props
        const time = getParameterByName(videoUrl, 't')

        const player = new Plyr(this.playerRef.current, {
          loop: { active: autoplay && loop },
          clickToPlay: false,
          youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, start: time ? parseInt(time) : 0 }
        })

        if (!autoplay) {
          player.on('timeupdate', this.onProgress)
          player.on('ended', this.onEnded)
        }

        player.on('ready', () => {
          this.setState({ isReady: true, duration: player.duration })

          if (autoplay) {
            if (isVisible) {
              player.embed.mute()
              player.embed.setVolume(0)
              player.play()
              this.setState({ isPlaying: true })
            }
          } else {
            player.embed.setVolume(50)
          }

          this.setState({ player })
        })
      }
    }
  }

  componentWillUnmount() {
    const { player } = this.state
    if (player) {
      if (this.isVimeoLink()) {
        player.off('pause', this.pause)
        if (!this.props.autoplay) {
          player.off('ended', this.onEnded)
          player.off('timeupdate', this.onProgress)
          player.off('bufferstart', this.onBufferStart)
          player.off('bufferend', this.onBufferEnd)
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { autoplay, isVisible } = this.props
    const { player } = this.state
    if (prevProps.isVisible !== isVisible) {
      if (!isVisible) {
        if (this.isVimeoLink()) {
          player.pause()
          this.timeout = setTimeout(() => {
            player.setCurrentTime(0)
            clearTimeout(this.timeout)
          }, 500)
        } else {
          this.onEnded()
        }
      }

      if (isVisible && !!autoplay) {
        this.play()
      }
    }
  }

  onProgress = t => {
    const { player } = this.state
    if (this.isVimeoLink()) {
      this.setState({ currentTime: Math.floor(t.seconds) })
    } else {
      if (!player.seeking) {
        this.setState({ currentTime: Math.floor(player.currentTime) })
      }
    }
  }

  onBufferStart = () => {
    this.setState({ isBuffering: true })
  }

  onBufferEnd = () => {
    this.setState({ isBuffering: false })
  }

  onEnded = () => {
    const { player } = this.state
    this.pause()
    if (this.isVimeoLink()) {
      player.setCurrentTime(0)
    } else {
      player.stop()
    }
  }

  play = () => {
    const { player, volumeOn } = this.state
    const isYoutube = this.isYoutubeLink()
    if (isYoutube && this.props.autoplay) {
      player.muted = !volumeOn
    }

    player.play()
    this.setState({ isPlaying: true })
  }

  pause = () => {
    this.state.player.pause()
    this.setState({ isPlaying: false })
  }

  togglePlay = () => {
    if (this.state.isPlaying) {
      this.pause()
    } else {
      this.play()
    }

    if (isMobile()) {
      this.toggleMobileControls()
    }
  }

  toggleMobileControls = () => {
    this.setState({ showMobileControls: !this.state.showMobileControls })
  }

  onRangeChange = ev => {
    const { timeValue, player } = this.state
    const value = isMobile() ? ev.target.value : timeValue
    if (this.isVimeoLink()) {
      this.pause()
      player.setCurrentTime(value)
      this.setState({ currentTime: value })
      this.play()
    } else {
      player.embed.seekTo(value)
      this.setState({ currentTime: value })
    }
  }

  onRangeHover = ev => {
    const sliderOffsetX = ev.target.getBoundingClientRect().left - document.documentElement.getBoundingClientRect().left
    const max = this.state.duration
    const sliderWidth = ev.target.offsetWidth - 1;
    const currentMouseXPos = (ev.clientX + window.pageXOffset) - sliderOffsetX;
    let sliderValAtPos = Math.round(currentMouseXPos / sliderWidth * max + 1);
    if (sliderValAtPos < 0) sliderValAtPos = 0;
    if (sliderValAtPos > max) sliderValAtPos = max;

    this.setState({ timePosition: currentMouseXPos, timeValue: sliderValAtPos - 1 })
  }

  onRangeOut = () => {
    this.setState({ timePosition: 0, timeValue: 0 })
  }

  toggleVolume = () => {
    const { volumeOn, player } = this.state
    const prevVolumeOn = volumeOn

    if (this.isVimeoLink()) {
      player.setVolume(prevVolumeOn ? 0 : 1)
    } else {
      if (prevVolumeOn) {
        player.embed.mute()
      } else {
        player.embed.unMute()
      }
      player.embed.setVolume(prevVolumeOn ? 0 : 50)
    }
    this.setState({ volumeOn: !prevVolumeOn })
  }

  isVimeoLink = () => {
    const { videoUrl } = this.props

    return videoUrl.includes('vimeo.com')
  }

  isYoutubeLink = () => {
    const { videoUrl } = this.props

    return videoUrl.includes('youtu')
  }

  render() {
    const { videoUrl, isFirstBlock, autoplay, imagePreview, isVisible } = this.props
    const { isPlaying, currentTime, timeValue, timePosition, isBuffering, showMobileControls, volumeOn } = this.state
    const imagePreviewUrl = _get(imagePreview, 'large[0]')
    const isMobileCheck = isMobile()
    const showPrediction = !isMobileCheck && Boolean(timePosition && timeValue)
    const showControls = isMobileCheck && showMobileControls
    const shouldShowVolume = isPlaying && !!autoplay

    return (
      <VideoWrapper isFirstBlock={isFirstBlock} isVisible={isVisible}>
        {shouldShowVolume && <VolumeContainer onClick={this.toggleVolume}>{volumeOn ? volumeOnIcon : volumeOffIcon}</VolumeContainer>}
        {!isPlaying && !autoplay && <CentralButton className="no-style-button" isMobile={isMobileCheck} onClick={this.togglePlay}>{play}</CentralButton>}
        {!autoplay && <VideoOverlay isMobile={isMobileCheck} isPlaying={isPlaying && !autoplay} imagePreviewUrl={isPlaying ? '' : imagePreviewUrl} onClick={() => isMobileCheck ? this.toggleMobileControls() : null}>
          <Controls showControls={showControls} isMobile={isMobileCheck}>
            {isPlaying && <CentralButton isMobile={isMobileCheck} className="no-style-button" onClick={this.togglePlay}>{isBuffering ? loader : pauseIcon}</CentralButton>}}
            <TimeContainer>
              <Time>{formatTime(currentTime)}</Time>
              <TimeLineWrapper>
                <TimeLine type="range" ref={this.range} isMobile={isMobileCheck} onMouseOut={!isMobileCheck ? this.onRangeOut : () => false} onMouseMove={!isMobileCheck ? this.onRangeHover : () => false} onChange={this.onRangeChange} min={0} max={this.state.duration} value={currentTime} />
                {showPrediction && <TimeLinePrediction xPos={timePosition}>{formatTime(timeValue)}</TimeLinePrediction>}
              </TimeLineWrapper>
            </TimeContainer>
          </Controls>
        </VideoOverlay>}
        <VideoIframeContainer>
          {this.isVimeoLink() ? <VideoIframe
            ref={this.playerRef}
            isFirstBlock={isFirstBlock}
            title='video'></VideoIframe>

            : <VideoIframe
              ref={this.playerRef}
              isFirstBlock={isFirstBlock}
              data-plyr-provider="youtube"
              data-plyr-embed-id={videoUrl}
            />}
        </VideoIframeContainer>

      </VideoWrapper>
    )
  }
}
