import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  padding: calc(var(--main-spacing) * 2) 0;

  @media only screen and (max-width: 600px) {
    padding: calc(var(--main-spacing-mobile) * 2) 0;
  }

  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
`
const List = styled.ul`
  margin-top: 16px;
  display: flex;

  @media only screen and (max-width: 600px) {
    display: block;
  }

  &:first-child {
    margin-top: 0;
  }
`

const ListItem = styled.li`
  margin: 0 8px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  @media only screen and (max-width: 600px) {
    margin: 8px 0;
  }
`

const emptyArray = []

class Footer extends PureComponent {
  render() {
    const { menu } = this.props

    return (
      <Wrapper>
        <nav>
          <List>
            {menu.map(menuItem => {
              const isExternal = !menuItem.url.includes('pantar.com')

              return <ListItem key={`footer_menu_${menuItem.ID}`}>
                <a href={menuItem.url} target={isExternal ? '_blank' : '_self'}>{menuItem.title}</a>
              </ListItem>
            })}
          </List>
          <List>
            <ListItem>© {new Date().getFullYear()} PANTAR.COM</ListItem>
          </List>
        </nav>
      </Wrapper>
    )
  }
}

export default connect(state => {
  return {
    menu: state.menus['secondary-menu'] || emptyArray
  }
})(Footer)
