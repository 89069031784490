export const setMenus = payload => ({
  type: 'SET_MENUS',
  payload
})

export const setPage = payload => ({
  type: 'SET_PAGE',
  payload
})

export const setHomepage = payload => ({
  type: 'SET_HOMEPAGE',
  payload
})

export const setShowMenu = payload => ({
  type: 'SHOW_MENU',
  payload
})

export const setColorStops = payload => ({
  type: 'SET_COLOR_STOPS',
  payload
})

export const setColorStopIndex = payload => ({
  type: 'SET_COLOR_STOP_INDEX',
  payload
})

export const setCurrentPage = payload => ({
  type: 'SET_CURRENT_PAGE',
  payload
})
