import React, { PureComponent } from 'react'
import { arrowLeft, arrowRight } from '../icons'
import { Link } from "react-router-dom";
import styled from 'styled-components'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  color: #DA8F72;

  h1 {
    margin: 0 8px;
    text-transform: uppercase;
    line-height: 1;
    font-size: 16px;
    @media only screen and (max-width: 600px) {
      margin: 0;
    }
  }


  .arrow-navigation-link {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  path {
    fill: ${props => props.shouldWhiteLink ? 'white' : 'black'}
  }
`

const CurrenyPageText = styled.h1`
  color: ${props => props.color};
`

const emptyArray = []

class ArrowNavigation extends PureComponent {

  getLinks = () => {
    const { mainMenu, currentPage } = this.props
    const mainMenuLength = mainMenu.length

    const currentIndex = mainMenu.findIndex(item => item.slug === currentPage)

    return {
      prev: currentIndex === 0 ? mainMenu[mainMenuLength - 1] : mainMenu[currentIndex - 1],
      current: mainMenu[currentIndex],
      next: currentIndex === mainMenuLength - 1 ? mainMenu[0] : mainMenu[currentIndex + 1]
    }
  }

  getLinkColor = () => {
    const { shouldWhiteLink } = this.props

    if (shouldWhiteLink) return 'white'
    return '#7B7A7A'
  }

  render() {
    const { mainMenu, currentPage, shouldWhiteLink } = this.props
    if (!mainMenu.length || !currentPage || currentPage === 'homepage') {
      return null
    }

    const links = this.getLinks()

    if (!links.current) {
      return null
    }

    return (
      <Wrapper shouldWhiteLink={shouldWhiteLink}>
        <Link className="arrow-navigation-link" to={'/' + links.prev.slug}>{arrowLeft}</Link>
        <CurrenyPageText color={this.getLinkColor()}>{links.current.title}</CurrenyPageText>
        <Link className="arrow-navigation-link" to={'/' + links.next.slug}>{arrowRight}</Link>
      </Wrapper>
    )
  }
}

export default connect(state => {
  return {
    mainMenu: state.menus['main-menu'] || emptyArray,
    currentPage: state.currentPage,
    colorStops: state.colorStops
  }
})(ArrowNavigation)
