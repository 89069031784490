/* eslint import/no-webpack-loader-syntax: off */

import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { arrowLeft, arrowRight, arrowDown } from '../icons'
import VideoPlayer from './VideoPlayer'
import jump from 'jump.js'
import ReactHtmlParser from 'react-html-parser'
import striptags from 'striptags'
import TouchSweep from 'touchsweep';

const Wrapper = styled.div`
  height: ${props => props.isFirstBlock ? `${props.elementHeight}px` : 'auto'};
  display: inline-block;
  width: 100%;
  overflow: hidden;

  ${props => !props.isFirstBlock ? css`
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-top: calc(var(--main-spacing) * 2);
    @media (max-width: 600px) {
      margin-top: calc(var(--main-spacing-mobile) * 2);

    }
  ` : css`
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      user-select: none;
      pointer-events: none;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(0,0,0,.5) 0%,
        rgba(0,0,0,.10) 20%,
        rgba(0,0,0,.10) 50%,
        rgba(0,0,0,.10) 80%,
        rgba(0,0,0,.5) 100%
      );
  }
  `}

  @media (max-width: 600px) {
    height: ${props => props.isFirstBlock ? `${props.elementHeight}px` : 'auto'};
  }

  .box {
    display: none;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  ${props => !props.isFirstBlock ? css`
    max-width: 1120px;
    width: 100%;
    margin: 0 var(--main-spacing);
    @media (max-width: 600px) {
      margin: 0 var(--main-spacing-mobile);
    }
  `: css`
    height: 100%;
  `}


  .arrow-down {
    position: absolute;
    bottom: 10px;
    right: var(--main-spacing);

    @media only screen and (max-width: 600px) {
      right: var(--main-spacing-mobile);
      bottom: 8px;
    }
  }
`

const ImageWrappo = styled.div`
  max-height: 630px;
  height: 55vw;
  background-image: linear-gradient(to right, rgba(85,85,85, .4) 0%, rgba(85,85,85, 0) 5%, rgba(85,85,85, 0) 95%, rgba(85,85,85, .4) 100%);
`

const Image = styled.div`
  background-image: ${props => `url(${props.imageUrl})`};
  background-position: center center;
  background-size: ${props => props.isFirstBlock ? 'cover' : 'contain'};
  background-repeat: no-repeat;
  position: absolute;
  z-index: ${props => props.isVisible ? 0 : -1};
  height: 100%;
  position: relative;

  @media (max-width: 600px) {
    height: 100%;
    position: relative;
  }

  top: 0;
`

const rightButtonStyle = isFirstBlock => {
  if (isFirstBlock) {
    return css`
      right: 0;
      text-align: right;
      margin-right: calc(var(--main-spacing) + 4px)!important;
      path {
        fill: white;
      }
    `
  }

  return css`
    right: calc(-1 * var(--main-spacing));
    text-align: right;
    padding-right: 10px!important;
    @media only screen and (max-width: 600px) {
      right: calc(-1 * var(--main-spacing-mobile));
    }
  `
}

const leftButtonStyle = isFirstBlock => {
  if (isFirstBlock) {
    return css`
      left: 0;
      text-align: left;
      margin-left: calc(var(--main-spacing) + 4px)!important;
      path {
        fill: white;
      }
    `
  }

  return css`
    left: calc(-1 * var(--main-spacing));
    text-align: left;
    padding-left: 10px!important;
    @media only screen and (max-width: 600px) {
      left: calc(-1 * var(--main-spacing-mobile));
    }
  `
}

const NavButton = styled.button`
  position: absolute;
  top: 0;
  bottom: var(--main-spacing);
  @media only screen and (max-width: 600px) {
    bottom: var(--main-spacing-mobile);
  }
  width: ${props => props.reducedBtnWidth ? '32px' : '35%'};
  z-index: 2;
  ${ props => props.isRight ? rightButtonStyle(props.isFirstBlock) : leftButtonStyle(props.isFirstBlock)}
`

const MediaContainer = styled.div`
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: ${props => props.isVisible ? 'opacity .2s linear' : 'none'};
  text-align: center;
  font-size: 0;
  height: 100%;
  position: ${props => props.isVisible ? 'relative' : 'absolute'};
`

const ImageCaption = styled.div`
  position: relative;
  right: 0;
  left: 50%;
  width: 70%;
  margin-left: -35%;
  margin-top: 4px;
  text-align: center;
  font-size: 13px;
  top: ${props => props.isFirstBlock ? 'calc(var(--main-spacing) * 2 * -1);' : '0'};
  color: ${props => props.isFirstBlock ? 'white' : '#7B7A7A'};
  z-index: ${props => props.isFirstBlock ? '2' : '0'};

  @media only screen and (max-width: 600px) {
    margin-top: 2px;
    top: ${props => props.isFirstBlock ? 'calc(var(--main-spacing-mobile) * 2 * -1);' : '0'};
  }
`

const RealImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  position: static;
  height: 100%;
  object-fit: cover;
  display: ${props => props.isVisible ? 'inline' : 'none'};
`

export default class Slideshow2 extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
      elementHeight: window.innerHeight
    }

    this.swiper = React.createRef()
  }

  componentDidMount() {
    const swiperDom = this.swiper.current
    if (swiperDom) {
      new TouchSweep(swiperDom, {}, 80);

      swiperDom.addEventListener('swipeleft', this.next);
      swiperDom.addEventListener('swiperight', this.prev);
      window.addEventListener('resize', this.onResize)
    }
  }

  componentWillUnmount() {
    const swiperDom = this.swiper.current
    if (swiperDom) {
      swiperDom.removeEventListener('swipeleft', this.next);
      swiperDom.removeEventListener('swiperight', this.prev);
    }

    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.setState({ elementHeight: window.innerHeight })
  }


  prev = () => {
    const { currentSlide } = this.state
    const { content } = this.props.block
    const nextSlide = currentSlide === 0 ? content.length - 1 : currentSlide - 1

    this.setState({ currentSlide: nextSlide })
  }

  next = () => {
    const { currentSlide } = this.state
    const { content } = this.props.block
    const nextSlide = content[currentSlide + 1] ? currentSlide + 1 : 0

    this.setState({ currentSlide: nextSlide })
  }

  getImageTemplate(block, isFirstBlock, isVisible) {
    if (block.video) {
      return null
    }

    return isFirstBlock
      ? <Image
        isFirstBlock={isFirstBlock}
        isVisible={isVisible}
        imageUrl={block.image.large[0]} />
      : <ImageWrappo>
        <RealImage src={block.image.medium[0]} alt='slide' isVisible={isVisible} />
      </ImageWrappo>
  }

  render() {
    const { isFirstBlock, block } = this.props
    const { content } = block
    const { currentSlide, elementHeight } = this.state
    const reducedBtnWidth = content[currentSlide].video_url && !content[currentSlide].autoplay

    return (
      <Wrapper isFirstBlock={isFirstBlock} elementHeight={elementHeight}>
        <ImageWrapper isFirstBlock={isFirstBlock} ref={this.swiper}>
          {content.length > 1 && <>
            <NavButton className='no-style-button' reducedBtnWidth={reducedBtnWidth} isFirstBlock={isFirstBlock} isRight={true} onClick={this.next}>{arrowRight}</NavButton>
            <NavButton className='no-style-button' reducedBtnWidth={reducedBtnWidth} isFirstBlock={isFirstBlock} onClick={this.prev}>{arrowLeft}</NavButton>
          </>}
          {content.map((imageBlock, index) => {
            const videoUrl = imageBlock.video_url
            const isVisible = index === currentSlide

            return <MediaContainer key={`image_${index}`} isVisible={isVisible}>
              {videoUrl
                ? <VideoPlayer
                  videoUrl={videoUrl}
                  isFirstBlock={isFirstBlock}
                  isVisible={isVisible}
                  imagePreview={imageBlock.image}
                  loop={imageBlock.loop}
                  autoplay={imageBlock.autoplay} />
                : this.getImageTemplate(imageBlock, isFirstBlock, isVisible)}
              <ImageCaption isFirstBlock={isFirstBlock}>{ReactHtmlParser(striptags(imageBlock.caption, ['em']))}</ImageCaption>
            </MediaContainer>
          })}
          {isFirstBlock && <button className='no-style-button' onClick={() => jump('.jump-to')}>{arrowDown}</button>}
        </ImageWrapper>
        <div className='jump-to'></div>
      </Wrapper>
    )
  }
}

export { Slideshow2 }
