import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { NavLink } from "react-router-dom";
import { setShowMenu } from '../actions';
import { close } from '../icons';


const Wrapper = styled.nav`
  position: fixed;
  box-shadow: 3px 0px 10px #555;
  top: 0;
  bottom: 0;
  width: 300px;
  padding: 0 var(--main-spacing);
  @media only screen and (max-width: 600px) {
    padding: 0 var(--main-spacing-mobile);
  }
  transform: ${props => props.showMenu ? 'translate3d(0, 0, 0)' : 'translate3d(-350px, 0, 0)'};
  background-color: ${props => props.bgcolor};
  transition: all .3s ease-in;
  opacity: 0.9;
  z-index: 4;
`

const LinkStyle = css`
  padding: 4px 0;
  display: block;
  color: black;
  text-transform: uppercase;

  &.active {
    color: #7C7B7B;
  }
`

const CloseButton = styled.button`
  line-height: 1px;
  margin: calc(var(--main-spacing) + 8px) 0 var(--main-spacing);
  @media only screen and (max-width: 600px) {
    margin: calc(var(--main-spacing-mobile) + 5px) 0 var(--main-spacing-mobile);
  }
  &:hover {
    path {
      fill: #7B7A7A;
    }
  }
`

const StyledNav = styled(NavLink)`
  ${LinkStyle}
`

const StyledLink = styled.a`
  ${LinkStyle}
`

const MenuWrapper = styled.ul`
  margin-bottom: var(--main-spacing);

  li {
    line-height: 1;

    &:last-child a {
      padding-bottom: 0;
    }
    &:first-child a {
      padding-top: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: var(--main-spacing-mobile);
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
`

class Menu extends PureComponent {
  onLinkClick = () => {
    const { dispatch } = this.props

    dispatch(setShowMenu(false))
  }
  render() {
    const { menus, showMenu, homepage, colorStops, colorStopIndex } = this.props
    const menuKeys = Object.keys(menus)

    return <>
      {showMenu && <Overlay onClick={this.onLinkClick} />}
      <Wrapper className="menu" showMenu={showMenu} bgcolor={colorStops ? colorStops[colorStopIndex] : 'white'}>
        <CloseButton className='no-style-button' onClick={this.onLinkClick}>{close}</CloseButton>
        {menuKeys.map(mkey => {
          const menuValues = menus[mkey]
          return <MenuWrapper key={mkey}>
            {menuValues.map(menuItem => {
              const isCustom = menuItem.type === 'custom'
              const isExternal = !menuItem.url.includes('pantar.com')
              return <li key={menuItem.ID}>
                {isCustom
                  ? <StyledLink href={menuItem.url} target={isExternal ? '_blank' : '_self'}>
                    {menuItem.title}
                  </StyledLink>
                  : <StyledNav
                    to={`/${menuItem.slug}`}
                    onClick={this.onLinkClick}
                    isActive={(match, location) => {
                      if (location.pathname === '/') {
                        return homepage === menuItem.slug
                      }

                      if (!match || !match.isExact) {
                        return false
                      }
                      return true
                    }}>
                    {menuItem.title}
                  </StyledNav>}
              </li>
            })}
          </MenuWrapper>
        })}
        <MenuWrapper>
          <li>© {new Date().getFullYear()} PANTAR.COM</li>
        </MenuWrapper>
      </Wrapper>
    </>
  }
}

export default connect(state => {
  return {
    showMenu: state.showMenu,
    menus: state.menus,
    homepage: state.homepage,
    colorStops: state.colorStops,
    colorStopIndex: state.colorStopIndex
  }
})(Menu)
