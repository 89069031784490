import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { setShowMenu } from '../actions'
import { burger } from '../icons'
import ArrowNavigation from './ArrowNavigation'
import { Link } from 'react-router-dom'

const Wrapper = styled.header`
  position: fixed;
  top: ${props => props.hasShadow ? '0px' : 'var(--main-spacing)'};
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--main-spacing);
  height: var(--main-spacing);
  z-index: 3;
  box-shadow: ${props => props.hasShadow ? '0px 3px 10px #555' : 'none'};
  background-color: ${props => props.hasShadow ? props.bgcolor : 'transparent'};
  transition: ${props => props.hasShadow
    ? 'top .3s linear, background-color .3s linear .3s, box-shadow .3s linear .3s'
    : 'top .1s linear'
  };

  @media only screen and (max-width: 600px) {
    padding: 0 var(--main-spacing-mobile);
    height: var(--main-spacing-mobile);
    top: ${props => props.hasShadow ? '0px' : 'var(--main-spacing-mobile)'};
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  line-height: 1;

  a:visited,
  a:link {
    transition: ${props => props.hasShadow ? 'color .3s linear .3s' : 'none'};
    color: ${props => props.hasShadow ? 'black' : 'white'};
  }
  path {
    transition: ${props => props.hasShadow ? 'fill .3s linear .3s' : 'none'};
    fill: ${props => props.hasShadow ? 'black' : 'white'};
  }
`

const HamburgerMenu = styled.button`
  line-height: 1;
  margin-right: 8px;
  visibility: ${props => props.hide ? 'hidden' : 'visible'};

  &:hover {
    path {
      fill: #7B7A7A;
    }
  }
`

class Header extends PureComponent {
  state = {
    hasShadow: false
  }

  onHamburgerClick = () => {
    const { dispatch, showMenu } = this.props
    dispatch(setShowMenu(!showMenu))
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const el = document.documentElement || document.body.parentNode || document.body
    const top = el.getBoundingClientRect().top * -1

    const { hasShadow } = this.state

    if (top > 0) {
      if (!hasShadow) {
        this.setState({ hasShadow: true })
      }
    } else {
      this.setState({ hasShadow: false })
    }
  }
  render() {
    const { colorStops, colorStopIndex, showMenu } = this.props
    return (
      <Wrapper hasShadow={this.state.hasShadow} bgcolor={colorStops ? colorStops[colorStopIndex] : '#ffffff'}>
        <LogoWrapper hasShadow={this.state.hasShadow}>
          <HamburgerMenu className="no-style-button" hide={showMenu} onClick={this.onHamburgerClick}>{burger}</HamburgerMenu>
          <Link to='/'>ALI HOSSAINI</Link>
        </LogoWrapper>
        <ArrowNavigation shouldWhiteLink={!this.state.hasShadow} />
      </Wrapper>
    )
  }
}

export default connect(state => {
  return {
    showMenu: state.showMenu,
    colorStops: state.colorStops,
    colorStopIndex: state.colorStopIndex
  }
})(Header)
