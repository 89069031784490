import React, { PureComponent } from 'react'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { getPage } from '../utils'
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser'
import { setPage, setHomepage, setColorStops, setColorStopIndex, setCurrentPage } from '../actions'
import styled, { css } from 'styled-components'
import Paragraph from './Paragraph'
import ListWithLinks from './ListWithLinks'
import SectionTitle from './SectionTitle'
import Slideshow from './Slideshow'
import gradstop from 'gradstop'
import { loader } from '../icons'
import Footer from './Footer'
import NotFound from './NotFound'

const defaultSlug = 'homepage'

const Wrapper = styled.div`
  ${props => props.elementHeight ? css`
    height: ${props => `${props.elementHeight}px`};
    display: flex;
    justify-content: center;
    align-items: center;
  `
  : null};


  .loader {
    width: 90px;
    height: 90px;
  }
`

export const GeneralHtmlWrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 0 var(--main-spacing-mobile);
`

const LoaderWrapper = styled.div`
  text-align: center;
`

const STOPS_NUMBER = 3

class Page extends PureComponent {
  state = {
    isLoading: false,
    gradient: [],
    stops: []
  }

  componentDidMount() {
    const slug = _get(this.props, 'match.params.slug', defaultSlug)
    this.getAndStorePage(slug)
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const el = document.documentElement || document.body.parentNode || document.body
    const top = el.getBoundingClientRect().top * -1
    const { stops } = this.state
    const { colorStop, dispatch } = this.props

    for (let index = 0; index < stops.length; index++) {
      const stop = stops[index];

      if (top <= stop.top) {
        if (colorStop !== stop.color) {
          dispatch(setColorStopIndex(index))
        }
        break;
      }
    }
  }

  getSwitchColorStops = () => {
    const body = document.body
    const html = document.documentElement
    const stops = []

    const fullHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const scrollAmount = fullHeight / STOPS_NUMBER

    for (let index = 0; index < STOPS_NUMBER; index++) {
      stops.push({
        color: this.props.colorStops[index],
        top: scrollAmount * index
      })
    }

    this.setState({ stops })
  }

  componentDidUpdate(prevProps) {
    const slug = _get(this.props, 'match.params.slug', defaultSlug)
    const prevSlug = _get(prevProps, 'match.params.slug', defaultSlug)

    if (slug !== prevSlug) {
      this.getAndStorePage(slug)
      window.scrollTo(0, 0)
    }
  }

  getAndStorePage = slug => {
    const { pages, dispatch, homepage } = this.props
    const currentPage = homepage && slug === 'homepage' ? homepage : slug

    dispatch(setCurrentPage(currentPage))

    if (!pages[slug]) {
      this.setState({ isLoading: true })
      getPage(slug)
        .then(resp => {
          this.setState({ isLoading: false })

          if (resp.is_homepage) {
            dispatch(setHomepage(resp.slug))
            dispatch(setCurrentPage(resp.slug))
          }

          dispatch(setPage({
            [resp.slug]: resp
          }))

          this.setColors(resp)
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    } else {
      this.setColors(pages[slug])
    }
  }

  setColors = page => {
    if (page.color_combination) {
      const colorArray = page.color_combination.split(',')
      const gradient = gradstop({
        stops: STOPS_NUMBER,
        inputFormat: 'hex',
        colorArray: ['#e3e3e3', colorArray[0]]
      });
      this.props.dispatch(setColorStops(gradient))
      this.getSwitchColorStops()
    }
  }

  getTemplate = (block, index, slug) => {
    switch (block.blockName) {
      case 'core/paragraph':
        return <Paragraph block={block} />
      case 'acf/listwithlinks':
        return <GeneralHtmlWrapper>
          <ListWithLinks block={block} />
        </GeneralHtmlWrapper>
      case 'acf/section-title':
        return <SectionTitle block={block} />
      case 'acf/slideshow':
        return <Slideshow isFirstBlock={index === 0} block={block} />
      default:
        return <GeneralHtmlWrapper>
          {ReactHtmlParser(block.innerHTML)}
        </GeneralHtmlWrapper>
    }
  }

  getContainerStyle(block) {
    const textAlign = _get(block.attrs, 'align') === 'center' ? 'center' : null
    return {
      textAlign
    }
  }

  render() {
    const { pages, homepage } = this.props
    const slug = _get(this.props, 'match.params.slug', defaultSlug)
    const page = slug === 'homepage' ? pages[homepage] : pages[slug]

    if (this.state.isLoading) {
      return <Wrapper elementHeight={window.innerHeight}>
        <LoaderWrapper>{loader}</LoaderWrapper>
      </Wrapper>
    }

    if (!page) {
      return <NotFound />
    }

    return <Wrapper>
      <Helmet>
        <title>Ali Hossaini - {page.title}</title>
      </Helmet>
      {page.blocks && page.blocks.map((block, index) => <div key={`block_${index}_${page.slug}`} style={this.getContainerStyle(block)}>
        {this.getTemplate(block, index, page.slug)}
      </div>)}
      <Footer />
    </Wrapper>
  }
}

export default connect(state => {
  return {
    pages: state.pages,
    homepage: state.homepage,
    colorStops: state.colorStops
  }
})(Page)
