import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  position: fixed;
  background-color: ${props => props.color};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${props => props.isVisible ? '1' : '0'};
  transition: all .5s linear;
`

class Background extends PureComponent {
  render() {
    const { colorStops, colorStopIndex } = this.props;

    if (!colorStops) {
      return null
    }

    return colorStops.map((color, index) => <Wrapper key={color} color={color} isVisible={index === colorStopIndex}></Wrapper>)
  }
}

export default connect(state => {
  return {
    colorStops: state.colorStops,
    colorStopIndex: state.colorStopIndex
  }
})(Background)
