import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { GeneralHtmlWrapper } from './Page'

const Wrapper = styled.div`
  text-align: center;
  padding-top: 120px;
`

const Title = styled.h1`
  margin-top: 0;
`

export default class NotFound extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Title>404, not found</Title>
        <GeneralHtmlWrapper>
          <p>The page you requested was not found, please go back to the <Link to='/'>Homepage</Link></p>
        </GeneralHtmlWrapper>
      </Wrapper>
    )
  }
}
