const fetchIt = (entity, options) => {
  const domain = window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok') ? 'http://dev.pantar.com/' : '/'
  const apiPrefix = 'wp-json/api/v1/'
  const url = domain + apiPrefix + entity
  return fetch(url, options)
    .then(resp => {
      if (!resp.ok) {
        throw new Error('Error with ' + url)
      }
      return resp.json()
    })
}

export const origin = window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok') ? 'http://dev.pantar.com' : window.location.origin

export const getParameterByName = (url, name) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getMenus = () => {
  return fetchIt('menus')
}

export const getPage = slug => {
  return fetchIt(`page/${slug}`)
}

export const isMobile = () => {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export const formatTime = time => {
  const sec_num = parseInt(time, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}
