import React, { PureComponent } from 'react'
import styled from 'styled-components'

const Wrapper = styled.h3`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
`

export default class SectionTitle extends PureComponent {
  render() {
    return (
      <Wrapper>
        {this.props.block.attrs.data.section_title}
      </Wrapper>
    )
  }
}
