import React, { PureComponent } from 'react'
import ReactHtmlParser, { processNodes } from 'react-html-parser'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GeneralHtmlWrapper } from './Page'
import truncate from 'truncate-html'
import _get from 'lodash/get'
import { origin } from '../utils'

const ReadMoreButton = styled.button`
  display: inline;
  margin-left: 4px;
`

function transform(node, index) {
  if (node.type === "tag" && node.name === "a") {
    const { attribs } = node

    if (!attribs.target && attribs.href.includes(origin) && !attribs.href.includes('.pdf')) {
      return <Link key={attribs.href + index} to={attribs.href.replace(origin, '')}>{processNodes(node.children, transform)}</Link>
    } else {
      return <a key={attribs.href + index} href={attribs.href} rel="noopener noreferrer" target='_blank'>
      {processNodes(node.children, transform)}
    </a>
    }
  }
}

const options = {
  decodeEntities: true,
  transform
};

const truncaateOptions = {
  ellipsis: '',
  byWords: true
}

export default class Paragraph extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showMoreText: false
    }

    this.paragraph = React.createRef()
  }

  componentDidMount() {
    const trimText = _get(this.props, 'block.attrs.trimText')

    if (trimText) {
      this.setState({ showMoreText: true })
    }
  }

  render() {
    const { showMoreText } = this.state
    const trimText = _get(this.props, 'block.attrs.trimText')
    const html = showMoreText ? truncate(this.props.block.innerHTML, 60, truncaateOptions) : this.props.block.innerHTML
    const template = ReactHtmlParser(html, options)

    return <GeneralHtmlWrapper className="with-inline-paragraphs" ref={this.paragraph}>
      {template}
      {trimText && <ReadMoreButton className="no-style-button" onClick={() => this.setState({ showMoreText: !showMoreText })}>{showMoreText ? 'More...' : 'Less...'}</ReadMoreButton>}
    </GeneralHtmlWrapper>
  }
}
