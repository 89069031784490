import React from 'react'

export const arrowLeft = <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.1 0L0 6L4.1 12H5.4L1.4 6L5.4 0H4.1Z" fill="black" />
</svg>

export const arrowRight = <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0L4 6L0 12H1.2L5.4 6L1.2 0H0Z" fill="black" />
</svg>

export const arrowDown = <svg className='arrow-down' width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.7 0.300001L6.7 4.3L0.700001 0.300001L0.700001 1.5L6.7 5.7L12.7 1.5L12.7 0.300001Z" fill="black" />
</svg>

export const burger = <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16 5H0V6.4H16V5Z" fill="black" />
  <path d="M16 0H0V1.4H16V0Z" fill="black" />
  <path d="M16 10H0V11.4H16V10Z" fill="black" />
</svg>

export const close = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.3 1.7L11.3 0.700001L6.1 5.9L1 0.700001L0 1.7L5.2 6.9L0 12L1 13L6.1 7.8L11.3 13L12.3 12L7.1 6.9L12.3 1.7Z" fill="black" />
</svg>

export const play = <svg width="74" height="74" className="play-icon" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M37 74C16.6 74 0 57.4 0 37C0 16.6 16.6 0 37 0C57.4 0 74 16.6 74 37C74 57.4 57.4 74 37 74ZM37 1C17.1 1 1 17.1 1 37C1 56.9 17.1 73 37 73C56.9 73 73 56.9 73 37C73 17.1 56.9 1 37 1Z" fill="white" />
  <path d="M30.8 13H29.5L45.9 37L29.5 61H30.8L47.4 37L30.8 13Z" fill="white" />
</svg>

export const pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" className="pause-icon" viewBox="0 0 74 74">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path fill="#fff" d="M37,.5V0A37,37,0,1,1,0,37,37,37,0,0,1,37,0V1A36,36,0,1,0,62.46,11.54,35.92,35.92,0,0,0,37,1V.5Z" />
      <polyline fill="#fff" points="27.81 18 27.81 56 28.81 56 28.81 18" />
      <polyline fill="#fff" points="45.19 18 45.19 56 46.19 56 46.19 18" />
    </g>
  </g>
</svg>

export const loader = <svg width="70" height="70" className="loader" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
  <g fill="none" fillRule="evenodd">
    <g transform="translate(1 1)" strokeWidth="1">
      <path d="M36 18c0-9.94-8.06-18-18-18">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 18 18"
          to="360 18 18"
          dur="1s"
          repeatCount="indefinite" />
      </path>
    </g>
  </g>
</svg>
